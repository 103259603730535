<template>
<div class='page'>
<div class="content">
    <div class="H1">{{CapabilitiesList.H1}}</div>
    <div class="model">
        <div class="left">
            <div class="N" v-for="(item,index) in CapabilitiesList.lists" :key="index">{{item.title}}</div>
        </div>
         <div class="rig"><img :src=CapabilitiesList.img alt=""></div>
    </div>
</div>
</div>
</template>
<script>
export default {
data() {
return {
   
}
},
methods: {},
mounted() {},
created() {},
props:{CapabilitiesList:{}},
watch:{ },
components:{}
}
</script>
<style scoped>
.page{
    width: 100%;
}
img{
    width: 100%;
}
.N{
    margin: 36px 0;
    color: #333333;
    font-size: 24px
}
.content{
    width:1200px ;
    margin: auto;
}
.model{
    display: flex;
    justify-content: space-between;
    width: 80%;

    align-items: center;
    /* background: red; */
    /* height: 60px; */
    margin: auto
}.H1{
        font-size: 34px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #DA251D;
    text-align: center;
    margin: 80px 0;
}
.rig{
    width:43vw ;
    max-width: 590px;
    min-width: 330px;
}
 @media screen and (max-width: 760px) {
     .rig{
         width: 100%;
     }
   .content{
        width: 90%;
        min-width: 330px
    }
    .left{
        width: 100%;
    }
    .N{
        text-align: left;
       margin: 20px 0;
       font-size: 18px
    }
    .model{
        display: flex;
            flex-direction: column-reverse;

    }
       .H1{
        font-size: 28px
    }

}
@media screen and (min-width: 760px) and (max-width: 1020px) {
   .content{
        width: 90%;
    }
  .N{
        font-size: 18px;
            margin: 20px 0;
    }
    .H1{
        font-size: 28px
    }
}
@media screen and (min-width: 1020px) and (max-width: 1350px) {
    .content{
        width: 90%;
    }
    .N{
        font-size: 21px;
            margin: 20px 0;
    }
    

}
@media screen and (min-width: 1350px) {
    
}
</style>