<template>
<div class='page'>
     <div class="bg"></div>
    <div class="content">
         <div class="H1">NICE WORDS FROM OUR CUSTOMERS</div>
         <div class="list">
             <div class="modelbg"  v-for="(item,index) in lists" :key="index">
            <div class="model" >
                 <div class="modelTop">“</div>
                 <div class="info">{{item.info}}</div>
                 <div class="modeltill">”</div>
             </div>
             <div class="modelti">
                 <div class="modeltiImg"><img :src=item.img alt=""></div>
                 <div class="modelname">{{item.name}}</div>
                 
             </div>
             </div>
            
         </div>
        
    </div>
   
</div>
</template>
<script>
export default {
data() {
return {
    lists:[{
        name:'-Simon Masters &Senior Director -Design, R&D',
        img: require("../assets/img/aerospace UAVname1.png"),
        info:`Hi HLH, the products were received yesterday with no problems and thank you the parts are fantastic. Thank you so much for your efforts in producing these so accurately and quickly.`

    },{
        name:'-Izzy Gal &Purchasing Manager',
        img: require("../assets/img/aerospace UAVname2.png"),
        info:`I just want to inform you that we are very pleased with the last order ( 1,000 boxes ) you've shipped us. The quality is excellent. As happy a customer, we will be happy to provide good feedback to your prospects. It's a real pleasure to work with you and your company!`

    },{
        name:'- Jacob Anderson &Engineering Manager',
        img: require("../assets/img/aerospace UAVname3.png"),
        info:`HLH has always delivered the perfect products totally meet our requirement. Also the service they provide is very nice. It’s really pleasure to work with HLH and we will keep long-time cooperation!`

    }]
}
},
methods: {},
mounted() {},
created() {},
props:{},
watch:{ },
components:{}
}
</script>
<style scoped>
.page{
    width: 100%;
    padding: 64px 0;
    margin: 100px 0 50px 0;

}

.modelTop{
    position: absolute;
    margin-left:-20px ;
    margin-top:-35px; 


font-size: 50px;
font-family: fantasy;
 
text-align: left;
color: #DA251D;
 
}.modeltill{

  /* position: absolute; */
font-size: 50px;
font-family: fantasy;
 
color: #DA251D;
text-align: right;
 
}
img{
    width: 100%;
}
.modeltiImg{
 min-width: 80px;
 width: 90px;
    margin-right:21px 
}
.info{
    text-align: left;
    line-height: 30px;
    font-size: 16px;
    font-family: 'WorkSans-Regular';
}
.modelname{
    width: 259px;
    text-align: left;
  
    margin-top:45px ;
 
font-family: Work Sans;
font-weight: bold;
color: #191919;
 

}
.H1{
 
 
font-size: 34px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #DA251D;
text-align: center;
margin-bottom: 120px;
}

.list{
    width: 100%;
    display: flex;
 
    justify-content: space-between;
}
.model{
  width: 100%;
    padding: 46px 40px;
     height: 400px;
 
    background: white;
    border: 1px solid #8C8C8C;
    box-shadow:0px 12px 10px -15px #8C8C8C

}
.modelbg{
      max-width: 380px;
    width: 380px;
    height: 100%;
}
.modelti{
    position: relative;
    margin-top: -30px;
    width: 100%;
    display: flex;
    padding-left: 16px;
}

.bg{
    height: 260px;
    width: 100%;
    background: #282828;


}
.content{
    width: 1200px;
    margin: auto;
    text-align: center;
    margin-top: -352px;
}

 @media screen and (max-width: 800px) {
     .H1{
         font-size: 28px
     }
     
.content{
        width: 90%;
        min-width: 330px
    }
    .list{
        display: block
    }
    .model{
        min-height: 250px;
        
        height: auto;
    }
    .modelbg{
        width: 100%;
        max-width: 100%;
    margin-bottom:60px 
    }
      .modelname{
          width: 100%;

        font-size: 16px
    }
    .modeltiImg{
        min-width: 80px;
        max-width: 90px
    }
}
@media screen and (min-width: 800px) and (max-width: 1020px) {
 .content{
        width: 90%;
    }
    .modelbg{
width: 28vw;

    }
    .model{
        padding: 5vw 3vw
    }
    
    .info{
        font-size: 14px;
        line-height: 25px;
    }
    .modelTop{
        font-size: 40px
    }
    .modeltill{
        font-size: 40px
    }
    .modelname{

        font-size: 13px;
 
    }
}
@media screen and (min-width: 1020px) and (max-width: 1350px) {
    .content{
        width: 90%;
    }
    .modelbg{
width: 28vw;

    }
    .model{
        padding: 5vw 3vw
    }
    
    .info{
        font-size: 15px;
        line-height: 25px;
    }
    .modelTop{
        font-size: 40px
    }
    .modeltill{
        font-size: 40px
    }
    .modelname{

        font-size: 14px;
 
    }
  

}
@media screen and (min-width: 1350px) {


    
}
</style>