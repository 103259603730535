<template>
<div class="slaServiceBox">
            <div class="w">
                <h1 class="title">{{industriesheadLists.H1}}</h1>
                <p class="info" v-html="industriesheadLists.info"></p>
                <div class="ProductionBox">
                    <span class="left">
                       <div class="imgBoxleft"> 
                           <div class="swiper-container"> 
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(item,index) in industriesheadLists.imgs" :key="index">
                       <img :src="item.src" :alt=industriesheadLists.H1>
                     
                    </div>
                     
                </div>
             
               
            </div>
                      
                    </div>
                    </span>
                    <span class="right">
                          <p class="title1">{{industriesheadLists.h2}}</p>
                        <div class="paramItem" v-for="(item,index) in industriesheadLists.data" :key="index"> 
                            <p class="size">
                                <!-- <span class="sizetitle" v-if="item.title">{{item.title}}</span>  -->
                                <span v-html="item.content" ></span>
                            </p>
                        </div>
                       
                    </span>
                </div>
                
            </div>
        </div>
</template>
<script>
 
export default {
data() {
return {
}
},
methods: {},
mounted() {  	 
},
created() {},
props:{
    industriesheadLists:{}
},
watch:{ },
components:{}
}
</script>
<style lang="scss" scoped>
.slaServiceBox {
        width: 100%;
        // height: 908px;
        background: #fff;
		padding: 1vw 0 60px 0;
		>.w {
              .right{
          width: 50%
        }
            // width: 1200px;
            margin: auto;
			>.title {
				text-align: center;
				// font-size: 36px;
				// margin-bottom: 60px;
                color: #DA251D;
                transition: 0.25s;
			}
			.ProductionBox{
				overflow: hidden;
                // display: flex;
                justify-content: space-between;
				.left {
					margin:10px 0px 0 0;
                    .imgBoxleft{
                        min-width: 330px;
                        max-width: 520px;
                        .swiper-container-pointer-events{
                            height: 400px;
                        }
                    }
				}
				.right {
					>.title1 {
						// font-size: 24px;
						margin-bottom: 15px;
                        color: #DA251D;
                        font-weight: bold
					}
					.paramItem {
						// width: 90%;
						font-size: 16px;
						border-bottom: 1px solid #ccc;
                        // padding: 10px 0;
                        overflow: hidden;
						.size {
							color: #666;
							margin: 10px 0;
                            display: flex;
                    
                            .icon {
                                color: #C2C2C2;
                                margin-right: 10px;
                                   display:block;
                            }
                           
                            span {
                                // word-break: break-all;     
                                word-break: keep-all;  //单词换行
                                line-height: 30px;
                                // float: left;
                                &:nth-child(2){
                                    display: inline-block;
                                    // width: 380px;

                                    line-height: 24px;
                                }
                            }
						}
					}
                    .paramItem:last-child{           	border-bottom: none;
                    }
				}
			}
			.info {
				// font-size: 18px;
				color: #333333;
				line-height: 30px;
                transition: .2s;
                line-height: 36px;
                font-family: Work Sans;
				margin:1vw 0 3vw 0;
                // text-align: center;
			}
		}
        img{
    width: 100%;
    height: 100%;
}

        
        .sizetitle{
            font-weight: bold
        }
    }
  

@media screen and (max-width: 560px) {   //最小  min-width="330px"
.title{
    font-size: 22px;
    margin-bottom: 20px
}
.w{
     width: 90%;
     min-width: 330px;
}

 .imgBoxleft{
                        //    width: 340px;
                          margin:  auto;
                        margin-bottom:50px; 
                       
                        }
                        .paramItem{
    width: 100%;
}
                         .ProductionBox{
                            display: block
                        }
                        .title1{
                            text-align: center;
                            font-size: 20px
                        }
                           .info{
                            font-size: 16px;
                              text-align: left;
                        }
                          .size{
                            font-size: 14px
                        }
                        .icon{
                       margin-top:5px 
                        }


}
 @media screen and (min-width: 560px) and (max-width: 760px) {
 .title{
    font-size: 30px;
    margin-bottom: 20px
}
.w{
     width: 90%;
}
 .imgBoxleft{
                        //    width: 480px;
                          margin:  auto;
                        margin-bottom:50px; 
                       
                        }
                        .paramItem{
    width: 100%;
    padding: 10px 0
}
                         .ProductionBox{
                            display: block
                        }
                        .title1{
                            text-align: center;
                            font-size: 20px
                        }
                           .info{
                            font-size: 16px;
                              text-align: left;
                        }
                          .size{
                            font-size: 14px
                        }
                        .icon{
                       margin-top:5px 
                        }


 }
//平板
@media screen and (min-width: 760px) and (max-width: 1020px) {

.w{
     width: 90%;
}
 .imgBoxleft{
                        //    width: 520px;
                          margin:  auto;
                        margin-bottom:50px; 
                       
                        }
                        .paramItem{
    width: 100%;
      padding: 10px 0
}
                         .ProductionBox{
                            display: block
                        }
                        .title1{
                            text-align: center;
                            font-size: 24px
                        }
                        .title{
    font-size: 34px;
    margin-bottom: 60px
}
   .info{
                            font-size: 18px;
                              text-align: center
                        }
                          .size{
                            font-size: 16px
                        }
}
@media screen and (min-width: 1020px) and (max-width: 1350px) {

.w{
     width: 90%;
}


.title1{
    font-size: 24px
}

 .ProductionBox{
                            display: flex
                        }
   .imgBoxleft{
                        //    width: 520px;
                          margin-right: 50px;
                        }
                        .paramItem{
    width: 100%;
    padding: 5px 0
}
.title{
    font-size: 34px;
    margin-bottom: 60px
}
   .info{
                            font-size: 18px;
                              text-align: center
                        }
                          .size{
                            font-size: 15px
                        }
}



@media screen and (min-width: 1350px) {  //最大限制   width="1280px"
.title{
    font-size: 55px;
    margin-bottom: 60px
}


.title1{
    font-size: 24px
}


.w{
     width: 1200px;
}
.paramItem{
    width: 90%;
     padding: 10px 0
}
   .imgBoxleft{
                        // width: 540px;
                         margin-right: 50px;
                        }
                        .ProductionBox{
                            display: flex
                        }
                        .info{
                            font-size: 18px;
                            text-align: center
                        }
                        .size{
                            font-size: 16px
                        }
}

 
 
</style>