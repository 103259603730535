<template>
<div class='page'> 
 
    <div class="banner">
            <div class="w">
                <div class="info">
                    <div class="title">Consumer Products</div>
                    <p class="title1">CNC machining enables the development of the consumer goods industry to have the ability to diversify, personalize and speed up, and endow consumer goods with a new look and new vitality.</p>
                    
                  
                </div>
            </div>
        </div>
  <myhead :hbg="hbg"></myhead>
  <bread :bbg="bbg"></bread> 
 <industrieshead :industriesheadLists="industriesheadLists"></industrieshead>
   <applications2 :ApplicationsLists="ApplicationsLists"></applications2>
    <capabilities :CapabilitiesList="CapabilitiesList"></capabilities>
  <customers></customers>
 
</div>
</template>
<script>
import myhead from "../../components/myHead.vue";
import bread from '../../components/bread.vue'
import industrieshead from "../../components/industrieshead.vue";
import applications2 from "../../components/Applications2.vue";
import customers from "../../components/Customers.vue"
import capabilities from "../../components/Capabilities.vue"
export default {
    components: {
    myhead,
    industrieshead,
    applications2,
    customers,
    capabilities,
    bread,
  },
  data() {
    return {
       CapabilitiesList:{
        H1:'CONSUMER PRODUCTS POST-PROCESSING CAPABILITIES',
        lists:[{ 
            title:'Anodizing',
        },{
            title:'Passivization',
        },{
            title:'Electroplating',
        },{
            title:'Powder Coating',
        },{
            title:'Insert Installation',
        },{
            title:'Laser Cutting',
        },],
        img: require('../../assets/img/consumerProductscal1.png')
    },
      industriesheadLists: {
        ref: "Consumer Products",
        H1: "CONSUMER PRODUCTS",
        info: `In addition to the basic requirements for consumer goods such as price and performance, the market also puts forward requirements for product appearance and personalized design, which requires manufacturers to focus on product iteration speed and novel design. CNC machining enables the development of the consumer goods industry to have the ability to diversify, personalize and speed up, and endow consumer goods with a new look and new vitality`,
        imgs: [
          {
            src: require("../../assets/img/consumerProductsv1.png")
          }
        ],
        h2: "Top Consumer Products Applications",
        data: [
          {
            content: "Customized low-volume production runs"
          },
          {
            content:
              "Rapid prototyping to accelerate design cycles"
          },
          {
            content:  "Functional prototypes"
          },
          {
            content: "Concept modeling for user testing"
          },
          {
            content: "Custom fixturing"
          }
        ]
      },
          hbg:{
        bg:'#ffffff'

    },
    bbg:{
 bg:'#ffffff',
 color:'#000000'
    },
      ApplicationsLists: {
        H1: "WHY HLH FOR DEVELOPMENT OF CONSUMER PRODUCTS？",
        data: [
          {
            img: require("../../assets/img/consumerProductsmodel1.png"),
            title: "Mass Customization",
            info:  "Leverage low-volume production capabilities to offer more customization options that customers demand."
          },
          {
            img: require("../../assets/img/consumerProductsmodel2.png"),
            title: "Material Diversification",
            info:  "Hundreds of materials to choose from give new life and energy to consumer products, bringing customer satisfaction."
          },
          {
            img: require("../../assets/img/consumerProductsmodel3.png"),
            title: "Comprehensive Solution",
            info: "The huge design team, mature technical team and caring service team have the ability to provide customers with comprehensive solutions from needs to products."
          }
        ]
      }
    };
  },
  
  methods: {},
  mounted() {},
  created() {},
  props: {},
  watch: {},

};
</script>
<style scoped lang="scss">
.page {
}

  .banner{
        background: url('~@/assets/img/consumerProductsbanner.png') no-repeat;
        
  
        width: 100%;
        // height: 560px;
        transition: 0.25s;
    
        padding-top: 70px;
        .w{
            display: flex;
            justify-content: flex-end;
            margin-top: 87px;
        }
    
        
        .title1{
            // font-size: 60px;
            margin-bottom: 2vw;
            transition: 0.25s;
            color: #ffffff;
          
      
        }
      
        .info {
      
    
            font-size: 24px;
            color: #333333;
            // margin-top: 30px;    
            .item{
                display: flex;
                align-items: center;
            
            }
            >p {
                // margin-bottom: 30px;
                img {
                    vertical-align: middle;
                    margin-right: 20px;
                    // width: 36px;
                    // height: 36px;
                    max-width: 36px;
                    min-width: 25px;
                 
                }
                span {
                    vertical-align: middle;
                }
            }
            >.title {
                // font-size: 48px;
                // margin-bottom: 0;
                color: white;
                font-weight: bold;
           transition: 0.25s;
            }
        }
    }
    
@media screen and (max-width: 760px) {   //最小  min-width="330px"
    .info{
      position: absolute;
    right: 2vw;
    top: 167px;
    }
    .w{
        width: 90%;
        min-width: 330px;
    }
    .title{
        font-size: 23px;
        font-weight: bold;
          margin-bottom: 6vw;
    } 
    .title1{
           font-size: 15px;
        // font-weight: bold;
        width: 62vw;
          // line-height: 25px;
    }
    img{
        width: 26px
    }
    .itemT{
        font-size: 14px;
    }
    p{
        margin-bottom:10px ;
    }
    .banner{
        height: 500px;
          background-position: 37% 100% !important;
    }
     .btn{
        font-size: 14px;
        margin-left: 48px;
    }
}

//平板
@media screen and (min-width: 760px) and (max-width: 1020px) {

    .w{
        width: 90%;
    }
    .title{
        font-size: 35px;
        margin-bottom: 54px;
    }
    .title1{
            font-size: 22px;
              line-height: 36px;
          width:50vw;
    }
    img{
        width: 30px;
    }
    .itemT{
        font-size: 22px;
    }
    p{
        margin-bottom:30px ;
    }
    .banner{
        height: 620px;
        background-position: center !important;
    }
    .btn{
        margin-left: 64px;
        font-size: 16px;
    }
    .info{
        margin-top: 30px;
    }
}
@media screen and (min-width: 1020px) and (max-width: 1350px) {
    .w{
        width: 90%;
    }
    .title{
        font-size: 40px;
        margin-bottom: 54px;
    }
   .title1{
        font-size: 22px;
        width:45vw;
          line-height: 36px;
    }
    img{
        width: 36px;}
        .itemT{
            font-size: 24px;
        }  p{
            margin-bottom:30px ;
        }
        .banner{
            height: 620px;
            background-position: center !important;
        }
        .btn{
            margin-left: 64px;
            font-size: 16px;
        }
        .info{
            margin-top: 30px;
        }
}



@media screen and (min-width: 1350px) {  //最大限制   width="1280px"
    .w{
        width: 90%;
    }
    .title{
        font-size: 50px;
        margin-bottom: 54px;
    }
    .title1{
           font-size: 24px;
        width: 650px;
          line-height: 36px;
    }
    .itemT{
        font-size: 24px;
    }
    img{
        width: 36px;}
        p{
            margin-bottom:30px ;
        }
        .banner{
            height: 620px;
            background-position: center !important;
        }
        .btn{
            margin-left: 64px;
            font-size: 16px;
        }
        .info{
            margin-top: 30px;
        }
}
</style>