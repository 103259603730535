<template>
 <div class="Applications" :style="{background:ApplicationsLists.bg}">
            <div class="w">
                <p class="title">{{ApplicationsLists.H1}}</p>
   
                <div class="list" >
                    <div class="model" :style="{background:ApplicationsLists.bg}"  v-for="(item ,index) in ApplicationsLists.data" :key="index">
                        <div class="modelimg">
                        <img :src="item.img" :alt=item.title>
                        </div> 
                        <p class="title1" :style="{color:ApplicationsLists.color1}" >{{item.title}}</p>
                        <p class="info" :style="{color:ApplicationsLists.color2}">{{item.info}}</p>
                    
                    </div>
                   
                </div>
            </div>
        </div>
</template>
<script>
export default {
data() {
return {
   
}
},
methods: {},
mounted() {},
created() {},
props:{
    ApplicationsLists:{

    }
},
watch:{ },
components:{}
}
</script>
<style lang="scss" scoped>
 .Applications {
     transition: 0.35s;
       
        background-color: #282828;
       
        // height: 980px;
        padding-bottom: 40px;
        .w {
             transition: 0.2s;
             margin: auto;
            .title {
                // font-size: 44px;
                color: #DA251D;
                // line-height: 60px;
             
                margin: 0 auto;
                font-weight: bold;
                text-align: center;
            } 
            .list {
                text-align: center;
                overflow: hidden;
             
               transition: 0.35s;
               
                justify-content: space-between;

                >.model {
                    // padding: 40px 23px 60px 23px;
                    // width: 360px;
                    // margin: auto;
                  transition: 0.2s;
                    background-color: #282828;
                
                    text-align: center;
                  
                    &:nth-last-child(1) {
                        margin-right: 0;
                    }
                    .modelimg{
                         transition: 0.2s;
                        // width: 200px;
                        margin: auto;
                 img {
                        width: 100%;
                        height: 100%;
                       
                       }
                    }
                    
                    .title1 {
                        // font-size: 24px;
                        color: #fff;
                       
                        line-height: 36px;
                        text-align: center;
                        font-weight: bold
                    }
                    .info {
                        color: #eee;
                      
                        line-height: 36px;
                    }
                }
            }
        }
    }

@media screen and (max-width: 850px) {   //最小  min-width="330px"
.Applications{
padding-top: 40px
}

.w{
    width: 90%;
    min-width: 330px;
}
.modelimg{
    width: 160px;
}
.model{
     padding: 40px 23px 60px 23px;
                    width: 100%;
                    margin: 20px 0
                  
}
.info{
  font-size: 15px;
}
.title1{
    font-size: 25px;
     margin: 10px 0 13px 0;
}
.list{
    display: block;
    // margin: auto
}
.title{
    font-size: 25px
}
 
}

//平板
@media screen and (min-width: 850px) and (max-width: 1020px) {

.w{
    width: 90%;
}
.modelimg{
   width: 13vw;
}
.model{
     padding: 20px 13px 40px 13px;
                    width: 28vw;
}.info{
  font-size: 15px;
}
.title1{
   font-size: 15px;
    margin: 10px 0 23px 0;
}
.list{
    display: flex;   margin-top: 50px;
}
.title{
    font-size: 34px;
      line-height: 60px;
}
.Applications{
 padding-top: 40px;
}
}
@media screen and (min-width: 1020px) and (max-width: 1350px) {
.w{
    width: 90%;
}
.modelimg{
    width: 13vw;
}
.model{
                      padding: 20px 23px 40px 23px;
                    width: 28vw;
}
.info{
  font-size: 16px;
}
.title1{
    font-size: 22px;
     margin: 20px 0 23px 0;
}
.list{
    display: flex;
       margin-top: 50px;
}
.title{
    font-size: 34px;
      line-height: 60px;
}
.Applications{
 padding-top: 40px;
}
}



@media screen and (min-width: 1350px) {  //最大限制   width="1280px"
.Applications{
 padding-top: 40px;
}

.w{
    width: 1200px;
}
.modelimg{
    width: 160px;
}
.model{
     padding: 40px 23px 60px 23px;
                    width: 360px;
}
.title1{
    font-size: 22px;
     margin: 30px 0 43px 0;
}
.info{
  font-size: 18px;
}
.list{
    display: flex;
       margin-top: 50px;
}
.title{
    font-size: 34px;
    line-height: 60px;
}
}

 
</style>